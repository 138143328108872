<template>
    <v-list class="vertical-list" flat>
        <v-list-item-group>
            <v-list-item v-for="element in item" class="d-flex align-center iconafterdash py-2"  :ripple="false">
                <v-list-item-icon class="align-self-center my-0">
                    <img src="@/assets/icons/svg/blue-point.svg" class="" alt="">
                </v-list-item-icon>
                <v-list-item-content class="">
                    <v-list-item-title class="white-space-initial">
                        <div class="d-flex align-center">
                            <div v-if="element.icon" class="">
                                <img :src="element.icon" alt="" class="mx-10 listiconimg">
                            </div>
                            <div class="d-flex flex-column">
                                <div class="black--text text-16-60" v-html="element.text"></div>
                                <div v-show="element.date" class="silver--text text-14" v-html="element.date"></div>
                            </div>

                        </div>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: 'VerticalList',
    props: ['item']
}
</script>

<style lang="scss">
</style>
