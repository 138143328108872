<template>
    <layout class="xs-bg-light" :xswhite="true">
        <v-container class="trackingpage mb-16">
            <breadcrumbs :items="breadcrumbs" :large="true" class="py-7 d-none d-md-block" textcolor="textdark"></breadcrumbs>

            <v-card flat style="overflow: hidden;">
                <v-row>
                    <v-col cols="12" lg="7">
                        <v-card-text class="px-10 py-8">
                            <h1 class="text-28-60">Track shipment</h1>
                            <p class="pt-4">Track your LTL, truckload, or intermodal shipment by entering <br/>your BOL number below to get instant freight tracking information</p>
                            <p class="mb-8">Need help? Call shipping expert at
<!--                              <a href="tel:+1 469 830 8777">+1 469 830 8777</a>-->
                            </p>
                            <div class="">
                                <label for="" >Shipment Number</label>
                                <v-text-field class="mt-2" v-model="track_number" placeholder="Shipment Number" outlined background-color="silver" v-on:keyup.enter="track"></v-text-field>
                            </div>
                            <v-btn x-large class="secondary py-8 text-14 text-transform-none w-100" @click="track" depressed>Track shipment</v-btn>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" lg="5" class="primary px-0 py-0 d-none d-md-flex">
                        <div class="p-relative">
                            <img src="@/assets/icons/track/cardicon.png" alt="" class="p-absolute b-0">
                        </div>
                    </v-col>

                </v-row>
            </v-card>
            <v-card v-if="order != null" class="px-7 py-4 mt-7" flat>
                <v-card-text class="pa-0">
                    <div class="top d-flex flex-row justify-space-between mb-4">
                        <div class="">
                            <div class="text-18-75 mb-1">
                                {{ getCity(order) }} <v-icon>mdi-chevron-right</v-icon> {{ getCity(order, 'to') }}
                            </div>
                            <div class="silver--text text-14">
                                Carier: {{ order.is_carrier ? order.carrier.name : 'Freight Crew Inc.' }}
                            </div>
                        </div>
                        <div class="text-right">
                            <div class="blue-text mb-1">
                                <img src="@/assets/icons/svg/timer-blue.svg" alt="">
                                <span class="ml-3" v-if="order.lastlog" v-html="order.lastlog.log"></span>
                            </div>
                            <div class="text-14 mb-4">Ship day: {{order.items[0].date}}</div>
                        </div>
                    </div>
                    <div class="content">
                        <v-card flat>
                            <v-card-text class="px-0">
                                <vertical-list :item="prepareLogs()"/>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </layout>
</template>

<script>
import { mapState } from 'vuex'

import Layout from '@/components/layouts/LightTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import VerticalList from '@/components/base/VerticalList.vue'

export default {
    components: {
        Layout,
        Breadcrumbs,
        VerticalList
    },
    data: () => ({
        track_number: '',
        order: null,
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Track shipment',
                disabled: true,
            }
        ]
    }),
    mounted() {
        if(this.$store.getters['track/track']){
            this.track_number = this.$store.getters['track/track']
            this.track()
        }
    },
    computed: {
        ...mapState({
            trackState: 'track/track'
        })
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'track/TRACK_ASSIGN') {
                this.track_number = state.track.track
            }
        })
    },
    methods: {
        track(){
            this.axios.get(process.env.VUE_APP_API_BASE + 'loads/v1/load/' + this.track_number).then(response => {
                this.order = response.data;
            });
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        },
        filterShipped(){
            this.orders.filter((item) => {
                return item.id_status == 11 || item.id_status == 12;
            })
        },
        prepareLogs(){
            if(this.order.logs){
                return this.order.logs.map((item) => {
                    return {
                        text: item.log,
                        date: this.dateFormat(item.updated_at)
                    }
                });
            }else{
                return [{text: 'Track data not found'}]
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.trackingpage{
    .v-text-field{
        fieldset{
            border: none;
        }
    }
    @media all and (max-width: 600px){
        .text-28-60{
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
        }
    }
    .vertical-list{
        .v-list-item{
            .v-list-item__icon{
                background-color: #fff;
                z-index: 10;
                img{
                    opacity: 0.5
                }
            }
        }
        .v-list-item:last-of-type{
            .v-list-item__icon{
                img{
                    opacity: 1;
                }
            }
        }
        .iconafterdash{
            padding-left: 0;
        }
        .iconafterdash::before{
            display: block;
            background-color: transparent;
            border-right: 2px solid #CDE0FF;
            content: " ";
            height: 100%;
            position: absolute;
            opacity: 1;
            right: auto;
            left: 11px;
            top: -50%;
        }
        .iconafterdash:first-of-type::before{
            display: none;
        }
        .iconafterdash:last-of-type::before{
            border-right: 2px solid #5496F0;
        }
    }
}
</style>
